.fontpicker,
.fontpicker * {
  box-sizing: border-box;
}

.MuiAccordionDetails-root .fontpicker {
  font-weight: 400;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.4375em;
  color: #233543;
  box-sizing: border-box;
  cursor: text;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background: unset;
  height: 40px;
  border: 0;
}

.fontpicker__preview {
  filter: invert(100%);
}

.MuiAccordionDetails-root .fontpicker__search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  background: unset;
  border: 1px solid rgb(156, 171, 183);
  border-radius: 8px;
}

.MuiAccordionDetails-root .fontpicker__search:focus {
  cursor: text;
  opacity: 1;
  outline: none;
  border-color: #024291;
  border-radius: 8px 8px 0 0;
}

.MuiAccordionDetails-root .fontpicker__popout {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: calc(12em + 1px);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  background: unset;
  opacity: 0;
  transform: scaleY(0.001);
  border: 1px solid #024291;
  border-radius: 0 0 8px 8px;
  border-top: 0;
}

.fontpicker__popout.fontpicker__active {
  opacity: 1;
  transform: scale(1);
}

.MuiAccordionDetails-root .fontpicker__option {
  background: #ffffff;
  cursor: pointer;
  padding: 4px 0;
}

.MuiAccordionDetails-root .fontpicker__option.selected {
  background: #f5f8fa;
}

.MuiAccordionDetails-root .fontpicker__nomatches {
  height: 2em;
  line-height: 2em;
  background: #fff;
  text-align: center;
  color: #333;
}

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(29 150 243 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(29 150 243 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
  body {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(35 53 67 / var(--tw-text-opacity));
}
  body:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
  .flex-basis-0 {
    flex-basis: 0%;
  }
  .flex-basis-auto {
    flex-basis: auto;
  }

  .basic-grid-container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
    padding-top: 1.5rem;
}

  @media (min-width: 600px) {
    .basic-grid-container {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
}

  @media (min-width: 964px) {
    .basic-grid-container {
        gap: 1.5rem;
        padding-top: 2rem;
    }
}

  .flex-basis-content {
    flex-basis: content;
  }
.container {
    width: 100%;
}
@media (min-width: 0px) {
    .container {
        max-width: 0px;
    }
}
@media (min-width: 600px) {
    .container {
        max-width: 600px;
    }
}
@media (min-width: 964px) {
    .container {
        max-width: 964px;
    }
}
@media (min-width: 1456px) {
    .container {
        max-width: 1456px;
    }
}
@media (min-width: 1792px) {
    .container {
        max-width: 1792px;
    }
}
.dataset-color {
  }
.dataset-color.dataset-color-1 {
    --tw-text-opacity: 1;
    color: rgb(29 150 243 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-1:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(102 189 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-2 {
    --tw-text-opacity: 1;
    color: rgb(75 23 230 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-2:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(234 229 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-3 {
    --tw-text-opacity: 1;
    color: rgb(0 163 158 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-3:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(123 224 221 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-4 {
    --tw-text-opacity: 1;
    color: rgb(4 107 217 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-4:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(4 107 217 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-5 {
    --tw-text-opacity: 1;
    color: rgb(2 66 145 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-5:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(179 222 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-6 {
    --tw-text-opacity: 1;
    color: rgb(10 204 139 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-6:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(122 226 191 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-7 {
    --tw-text-opacity: 1;
    color: rgb(32 8 153 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-7:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(106 77 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-8 {
    --tw-text-opacity: 1;
    color: rgb(10 102 102 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-8:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(207 250 248 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-9 {
    --tw-text-opacity: 1;
    color: rgb(149 128 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-9:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(189 176 255 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-10 {
    --tw-text-opacity: 1;
    color: rgb(10 88 63 / var(--tw-text-opacity));
}
.dataset-color.dataset-color-10:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(10 204 139 / var(--tw-text-opacity));
}
.dataset-background {
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 0.5rem;
    flex-basis: 1.5rem;
}
.dataset-background.dataset-background-1 {
    --tw-bg-opacity: 1;
    background-color: rgb(29 150 243 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-1:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(102 189 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-2 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 23 230 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-2:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(234 229 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-3 {
    --tw-bg-opacity: 1;
    background-color: rgb(0 163 158 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-3:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(123 224 221 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-4 {
    --tw-bg-opacity: 1;
    background-color: rgb(4 107 217 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-4:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(4 107 217 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-5 {
    --tw-bg-opacity: 1;
    background-color: rgb(2 66 145 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-5:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(179 222 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-6 {
    --tw-bg-opacity: 1;
    background-color: rgb(10 204 139 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-6:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(122 226 191 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-7 {
    --tw-bg-opacity: 1;
    background-color: rgb(32 8 153 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-7:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(106 77 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-8 {
    --tw-bg-opacity: 1;
    background-color: rgb(10 102 102 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-8:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(207 250 248 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-9 {
    --tw-bg-opacity: 1;
    background-color: rgb(149 128 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-9:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(189 176 255 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-10 {
    --tw-bg-opacity: 1;
    background-color: rgb(10 88 63 / var(--tw-bg-opacity));
}
.dataset-background.dataset-background-10:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(10 204 139 / var(--tw-bg-opacity));
}
.chart-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 2/1;
}
.layout-sidenav {
    width: 0px;
    max-width: 0px;
    min-width: 0px;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (min-width: 964px) {
  .layout-sidenav {
        width: 4rem;
    max-width: 64px;
    min-width: 64px;
    }
}
.layout-sidenav {
    z-index: 1200;
    transform: translateX(0px);
    transition-property: min-width, transform, width;
    transition-duration: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    background: linear-gradient(180deg, #046bd9 0%, #012961 100%);
  }
.layout-sidenav.open-mobile {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
@media (min-width: 600px) {
  .layout-sidenav.open-mobile {
        width: 16rem;
    max-width: 256px;
    min-width: 256px;
    }
}
@media (min-width: 964px) {
  .layout-sidenav.open-mobile {
        width: 4rem;
    max-width: 64px;
    min-width: 64px;
    }
  .layout-sidenav.hovered {
        width: 16rem;
    max-width: 256px;
    min-width: 256px;
    }
  .layout-sidenav.touched {
        width: 16rem;
    max-width: 256px;
    min-width: 256px;
    }
  .layout-sidenav.expanded {
        width: 16rem;
    max-width: 256px;
    min-width: 256px;
    }
}
@media (min-width: 1456px) {
    .layout-sidenav.expanded {
        position: sticky;
    }
}
@media (min-width: 964px) {
  .layout-sidenav.\!expanded {
        width: 16rem;
    max-width: 256px;
    min-width: 256px;
    }
}
@media (min-width: 1456px) {
    .layout-sidenav.\!expanded {
        position: sticky;
    }
}
.layout-main.layout-main-light-bg {
    --tw-bg-opacity: 1;
    background-color: rgb(240 248 255 / var(--tw-bg-opacity));
}
.layout-main.layout-main-light-bg:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}
.layout-main.layout-main-customers {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.layout-main.layout-main-customers:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}
.layout-main.layout-main-customer {
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.layout-main.layout-main-customer:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}
.layout-main {
    box-sizing: border-box;
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
}
@media (min-width: 964px) {
    .layout-main {
        padding-left: 4rem;
    }
}
.layout-main:has(iframe) {
    line-height: 0;
  }
.layout-main-inverted {
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.layout-main-inverted:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
@media (min-width: 1456px) {
  .layout-main.expanded {
    max-width: calc(100% - 256px);
    padding-left: 0px;
  }
  .layout-main.\!expanded {
    max-width: calc(100% - 256px);
    padding-left: 0px;
  }
}
.layout-top-nav-max-width {
    max-width: 100%;
}
@media (min-width: 964px) {
  .layout-top-nav-max-width {
    max-width: calc(100% - 64px);
  }
}
@media (min-width: 1456px) {
  .layout-main.expanded .layout-top-nav-max-width {
    max-width: calc(100% - 256px);
  }
  .layout-main.\!expanded .layout-top-nav-max-width {
    max-width: calc(100% - 256px);
  }
}
.layout-main-max-width {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
@media (min-width: 1456px) {
  .layout-main-max-width {
    max-width: 1200px;
  }
}
@media (min-width: 1792px) {
  .layout-main-max-width {
    max-width: 1536px;
  }
}
.layout-main-full-width {
    max-width: 100%;
}
@media (min-width: 1456px) {
  .layout-main-full-width {
    max-width: 100vw;
  }
}
.layout-primary-nav-item {
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border-radius: 0.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    text-decoration-line: none;
    padding-left: 14px;
}
.layout-primary-nav-item:hover {
    background-color: rgba(239, 241, 243, 0.04);
  }
.layout-primary-nav-item .icon {
  }
.layout-primary-nav-item .label {
    margin-left: 1rem;
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.layout-primary-nav-item .status {
    margin-right: 1rem;
}
.layout-primary-nav-item.active {
    background-color: rgba(11, 26, 38, 0.12);
  }
.layout-primary-nav-item.\!active {
    background-color: rgba(11, 26, 38, 0.12) !important;
  }
.layout-sidenav.hovered .opt-in-button,
  .layout-sidenav.expanded .opt-in-button,
  .layout-sidenav.hovered.expanded .opt-in-button,
  .layout-sidenav.open-mobile .opt-in-button {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(2 66 145 / var(--tw-text-opacity));
}

  .layout-sidenav.\!expanded .opt-in-button,
  .layout-sidenav.hovered.\!expanded .opt-in-button {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(2 66 145 / var(--tw-text-opacity));
}
.opt-in-button:hover,
  .layout-sidenav.open-mobile .opt-in-button:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 248 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(2 66 145 / var(--tw-text-opacity));
}
.opt-in-button .label {
    margin-left: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.opt-out-button .label {
    margin-left: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.layout-secondary-nav-container {
    overflow: hidden;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(222 227 231 / var(--tw-border-opacity));
}
.layout-secondary-nav-item {
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    display: flex;
    height: 3rem;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border-radius: 0.5rem;
    border-style: none;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 0.75rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    text-decoration-line: none;
}
.layout-secondary-sub-nav-item {
    box-sizing: border-box;
    display: none;
    height: 2rem;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    border-radius: 0.5rem;
    border-style: none;
    background-color: transparent;
    padding-left: 1rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    text-decoration-line: none;
}
.layout-secondary-sub-nav-item .label {
    margin-left: 2rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
}
.layout-secondary-nav-item.active {
    background-color: rgba(11, 26, 38, 0.12);
  }
.layout-secondary-nav-item.\!active {
    background-color: rgba(11, 26, 38, 0.12) !important;
  }
.layout-secondary-nav-item:hover,
  .layout-secondary-sub-nav-item:hover {
    background-color: rgba(239, 241, 243, 0.04);
  }
.layout-secondary-nav-item .label {
    margin-left: 1rem;
    display: flex;
    width: 100%;
    flex: 1 1 0%;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem;
}
.layout-primary-nav-item .arrow {
  }
.layout-secondary-nav-submenu {
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
    overflow: hidden;
    height: 0px;
    transform: translateY(0px);
    transition-property: transform, height;
    transition-duration: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.layout-secondary-nav-submenu.expanded {
    height: auto;
  }
.layout-secondary-nav-submenu.\!expanded {
    height: auto !important;
  }
.layout-secondary-nav-submenu.expanded > .layout-secondary-sub-nav-item {
    display: flex;
}
.layout-secondary-nav-submenu.\!expanded > .layout-secondary-sub-nav-item {
    display: flex;
}
.layout-sidenav:not(.hovered, .touched, .open-mobile, .expanded)
    .layout-secondary-nav-submenu.expanded {
    height: 0px;
  }
.layout-sidenav:not(.hovered, .touched, .open-mobile, .\!expanded)
    .layout-secondary-nav-submenu.\!expanded {
    height: 0px !important;
  }
.layout-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
}
.layout-user .layout-user-details {
    margin-left: 0.75rem;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    white-space: nowrap;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    max-width: calc(100% - 84px);
}
.layout-user .layout-user-details .marketplace {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.layout-user .layout-user-details .\!marketplace {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.layout-user .layout-user-details .plan {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    --tw-text-opacity: 1;
    color: rgb(189 199 207 / var(--tw-text-opacity));
}
.layout-user .layout-user-details .menu {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.mobile-menu-button {
    margin-left: 0.75rem;
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-style: none;
    background-color: transparent;
}
.layout-mobile-top-nav {
    position: fixed;
    top: 3.5rem;
    left: 0px;
    right: 0px;
    z-index: 40;
    display: flex;
    height: 3.5rem;
    align-items: center;
    justify-content: flex-start;
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.layout-mobile-top-nav:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
.layout-mobile-top-nav {
    transition-property: opacity translateY;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
.layout-mobile-top-nav.not-scrolled {
    opacity: 1;
    transform: translateY(0px);
}
.layout-mobile-top-nav.scrolled {
    opacity: 0;
    transform: translateY(-24px);
}
.layout-main.layout-main-apps {
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.layout-main.layout-main-apps:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
.layout-main-apps .app-page-header {
    margin-bottom: -9rem;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-bottom-color: rgb(222 227 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-bottom: 1.5rem;
    padding-top: 9rem;
}
.layout-main-apps .app-page-header:is(.dark *) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(35 53 67 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
}
@media (min-width: 964px) {
    .layout-main-apps .app-page-header {
        margin-bottom: -6rem;
        padding-top: 6rem;
    }
}
.layout-main-apps .app-page-header .app-page-header-contents {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
@media (min-width: 1456px) {
  .layout-main-apps .app-page-header .app-page-header-contents {
    max-width: 1200px;
  }
}
@media (min-width: 1792px) {
  .layout-main-apps .app-page-header .app-page-header-contents {
    max-width: 1536px;
  }
}
.settings-layout {
    position: relative;
}
.settings-layout .settings-layout-header {
    position: fixed;
    right: 0px;
    top: 0px;
    left: 0px;
    z-index: 30;
    display: flex;
    height: 3rem;
    align-items: center;
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-bottom-color: rgb(222 227 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 0.5rem;
}
.settings-layout .settings-layout-header:is(.dark *) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(35 53 67 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
}
@media (min-width: 964px) {
    .settings-layout .settings-layout-header {
        height: 4.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
.settings-layout .settings-layout-top-nav {
    position: relative;
    margin-top: 3rem;
    height: 6rem;
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-bottom-color: rgb(222 227 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.settings-layout .settings-layout-top-nav:is(.dark *) {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(35 53 67 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
@media (min-width: 964px) {
    .settings-layout .settings-layout-top-nav {
        display: none;
    }
}
.settings-layout .settings-layout-body {
    position: relative;
    display: flex;
}
@media (min-width: 964px) {
    .settings-layout .settings-layout-body {
        margin-top: 4.5rem;
    }
}
.settings-layout .settings-layout-side-nav {
    left: 0px;
    bottom: 0px;
    top: 4.5rem;
    display: none;
    width: 16rem;
    border-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-right-color: rgb(222 227 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
    padding: 1.5rem;
}
.settings-layout .settings-layout-side-nav:is(.dark *) {
    --tw-border-opacity: 1;
    border-right-color: rgb(35 53 67 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
@media (min-width: 964px) {
    .settings-layout .settings-layout-side-nav {
        position: fixed;
        display: block;
    }
}
.settings-layout .settings-layout-main {
    position: relative;
    flex: 1 1 auto;
}
@media (min-width: 964px) {
    .settings-layout .settings-layout-main {
        margin-left: 16rem;
    }
}
.settings-layout .settings-layout-main.settings-layout-main-full-width {
    max-width: 100%;
}
@media (min-width: 1456px) {
  .settings-layout .settings-layout-main.settings-layout-main-full-width {
    max-width: 100vw;
  }
}
.settings-layout .settings-layout-main.settings-layout-main-max-width {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
@media (min-width: 1456px) {
  .settings-layout .settings-layout-main.settings-layout-main-max-width {
    max-width: 1200px;
  }
}
@media (min-width: 1792px) {
  .settings-layout .settings-layout-main.settings-layout-main-max-width {
    max-width: 1536px;
  }
}
.settings-layout .settings-layout-main.settings-layout-height-desktop {
    height: calc(100vh - 5rem);
  }
.settings-layout .settings-layout-main.settings-layout-height-mobile {
    height: calc(100vh - 9.5rem);
  }
.auth-marketing {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    --tw-bg-opacity: 1;
    background-color: rgb(0 17 52 / var(--tw-bg-opacity));
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    --tw-shadow: inset 80px 0 80px -80px rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: inset 80px 0 80px -80px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.auth-marketing:is(.dark *) {
    --tw-shadow: inset 80px 0 80px -80px rgba(0, 0, 0, 0.25);
    --tw-shadow-colored: inset 80px 0 80px -80px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media (min-width: 964px) {
    .auth-marketing {
        display: flex;
        min-height: 100vh;
    }
}
.auth-marketing img {
    height: auto;
    width: 100%;
}
.objectfit-contain {
    -o-object-fit: contain;
       object-fit: contain;
  }
.grey-text {
    --tw-text-opacity: 1;
    color: rgb(90 115 134 / var(--tw-text-opacity));
}
.grey-text:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(189 199 207 / var(--tw-text-opacity));
}
.error-text {
    --tw-text-opacity: 1;
    color: rgb(217 22 54 / var(--tw-text-opacity));
}
.error-text:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 153 161 / var(--tw-text-opacity));
}
.stretchable-card > div > div,
  .stretchable-card > div,
  .stretchable-card {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.background-color-transition {
    transition: background-color 0.25s ease;
  }
.dashboard-card {
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 1rem;
    --tw-shadow: 0px 0px 1px 0px rgba(17, 17, 17, 0.15), 0px 1px 5px 0px rgba(17, 17, 17, 0.15);
    --tw-shadow-colored: 0px 0px 1px 0px var(--tw-shadow-color), 0px 1px 5px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dashboard-card:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
    --tw-shadow: 0px 0px 1px rgba(245, 248, 250, 0.15), 0px 1px 5px rgba(245, 248, 250, 0.15);
    --tw-shadow-colored: 0px 0px 1px var(--tw-shadow-color), 0px 1px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media (min-width: 600px) {
    .dashboard-card {
        padding: 1.5rem;
    }
}
.recent-activity-card {
    height: 324px;
}
@media (min-width: 1456px) {
  .recent-activity-card {
    max-height: calc(100vh - 10rem);
  }
}
@media (min-width: 964px) {
    .recent-activity-card {
        height: 432px;
    }
}
@media (min-width: 1456px) {
    .recent-activity-card {
        height: auto;
    }
}
.recent-activity-content {
    margin-right: -1rem;
    height: 244px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
@media (min-width: 1456px) {
  .recent-activity-content {
    max-height: calc(100vh - 15.5rem);
  }
}
@media (min-width: 600px) {
    .recent-activity-content {
        margin-right: -1.5rem;
    }
}
@media (min-width: 964px) {
    .recent-activity-content {
        height: 336px;
    }
}
@media (min-width: 1456px) {
    .recent-activity-content {
        height: auto;
    }
}
.backface-hidden {
    backface-visibility: hidden;
  }
.rotate-y-180,
  .hovering {
    transform: rotateY(180deg);
  }
.min-h-78 {
    min-height: 78px;
  }
.app-card {
    background-color: transparent;
}
.app-card .MuiCardActionArea-root {
    height: 100%;
}
.branding-settings-page .layout-main-max-width {
    max-width: 1536px;
  }
.branding-settings-wrapper {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
}
@media (min-width: 964px) {
    .branding-settings-wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media (min-width: 1456px) {
    .branding-settings-wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
.branding-settings-wrapper {
    min-height: calc(100% + 5rem);
  }
.branding-settings-col1 {
    grid-column: span 1 / span 1;
    border-width: 0px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
@media (min-width: 964px) {
    .branding-settings-col1 {
        border-right-width: 1px;
        border-style: solid;
        --tw-border-opacity: 1;
        border-right-color: rgb(222 227 231 / var(--tw-border-opacity));
        padding-right: 1.5rem;
    }
    .branding-settings-col1:is(.dark *) {
        --tw-border-opacity: 1;
        border-right-color: rgb(35 53 67 / var(--tw-border-opacity));
    }
}
.branding-settings-col2 {
    display: flex;
    justify-content: center;
}
@media (min-width: 964px) {
    .branding-settings-col2 {
        grid-column: span 1 / span 1;
    }
}
@media (min-width: 1456px) {
    .branding-settings-col2 {
        grid-column: span 2 / span 2;
    }
}
.Mui-disabled .branding-override-toggle-link {
    pointer-events: none;
    color: inherit;
}
.page-type-icon {
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
    flex-basis: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
}
.page-type-icon svg {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.page-type-icon svg:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(11 26 38 / var(--tw-text-opacity));
}
.page-type-icon.page-type-sales-page {
    --tw-bg-opacity: 1;
    background-color: rgb(8 155 106 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-sales-page:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(122 226 191 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-landing-page {
    --tw-bg-opacity: 1;
    background-color: rgb(106 77 255 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-landing-page:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(189 176 255 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-lead-capture {
    --tw-bg-opacity: 1;
    background-color: rgb(255 51 170 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-lead-capture:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 133 204 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-link-in-bio {
    --tw-bg-opacity: 1;
    background-color: rgb(4 107 217 / var(--tw-bg-opacity));
}
.page-type-icon.page-type-link-in-bio:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(140 205 255 / var(--tw-bg-opacity));
}
.page-settings-header {
    position: fixed;
    top: 3.5rem;
    z-index: 50;
    height: 3.5rem;
    width: 100%;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}
@media (min-width: 964px) {
    .page-settings-header {
        top: 0px;
        height: 4rem;
        border-bottom-width: 1px;
        border-style: solid;
        --tw-border-opacity: 1;
        border-color: rgb(222 227 231 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }
    .page-settings-header:is(.dark *) {
        --tw-border-opacity: 1;
        border-color: rgb(35 53 67 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(11 26 38 / var(--tw-bg-opacity));
    }
}
.page-settings-header .page-type-icon {
    height: 2rem;
    width: 2rem;
    flex-basis: 2rem;
}
.page-settings-header .page-type-icon svg {
    height: 1.25rem;
    width: 1.25rem;
}
.recently-updated-page .page-type-icon {
    height: 1.5rem;
    width: 1.5rem;
    flex-basis: 1.5rem;
}
.recently-updated-page .page-type-icon svg {
    height: 0.75rem;
    width: 0.75rem;
}
.domain-card {
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.5rem;
    border-style: none;
    background-color: transparent;
    padding: 1rem;
    text-align: left;
}
.domain-card:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 248 255 / var(--tw-bg-opacity));
}
.domain-card:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}
.domain-card.selected {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 255 / var(--tw-bg-opacity));
}
.domain-card.selected:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(2 66 145 / var(--tw-bg-opacity));
}
.strong-ordered-list {
    margin-bottom: 1.5rem;
    list-style-type: none;
    padding-left: 0px;
}
.strong-ordered-list li {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
}
.strong-ordered-list li .marker {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    display: flex;
    height: 1.25rem;
    width: 1.25rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(4 107 217 / var(--tw-bg-opacity));
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.strong-ordered-list li .marker:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(140 205 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(11 26 38 / var(--tw-text-opacity));
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.bottom-0 {
    bottom: 0px;
}
.left-0 {
    left: 0px;
}
.left-3 {
    left: 0.75rem;
}
.top-0 {
    top: 0px;
}
.top-3 {
    top: 0.75rem;
}
.z-10 {
    z-index: 10;
}
.z-50 {
    z-index: 50;
}
.col-span-1 {
    grid-column: span 1 / span 1;
}
.col-span-10 {
    grid-column: span 10 / span 10;
}
.col-span-12 {
    grid-column: span 12 / span 12;
}
.col-span-2 {
    grid-column: span 2 / span 2;
}
.col-span-3 {
    grid-column: span 3 / span 3;
}
.col-span-4 {
    grid-column: span 4 / span 4;
}
.col-span-6 {
    grid-column: span 6 / span 6;
}
.row-span-2 {
    grid-row: span 2 / span 2;
}
.row-span-3 {
    grid-row: span 3 / span 3;
}
.m-0 {
    margin: 0px;
}
.m-auto {
    margin: auto;
}
.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
}
.-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.-mb-14 {
    margin-bottom: -3.5rem;
}
.-ml-2 {
    margin-left: -0.5rem;
}
.-mt-2 {
    margin-top: -0.5rem;
}
.-mt-6 {
    margin-top: -1.5rem;
}
.-mt-8 {
    margin-top: -2rem;
}
.-mt-9 {
    margin-top: -2.25rem;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}
.mb-12 {
    margin-bottom: 3rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-3 {
    margin-bottom: 0.75rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.ml-0 {
    margin-left: 0px;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-12 {
    margin-left: 3rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-4 {
    margin-left: 1rem;
}
.ml-6 {
    margin-left: 1.5rem;
}
.ml-\[3\.25rem\] {
    margin-left: 3.25rem;
}
.ml-\[50px\] {
    margin-left: 50px;
}
.ml-auto {
    margin-left: auto;
}
.mr-0 {
    margin-right: 0px;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-4 {
    margin-right: 1rem;
}
.mr-6 {
    margin-right: 1.5rem;
}
.mr-\[106px\] {
    margin-right: 106px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mt-14 {
    margin-top: 3.5rem;
}
.mt-16 {
    margin-top: 4rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-28 {
    margin-top: 7rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mt-\[0\.125rem\] {
    margin-top: 0.125rem;
}
.box-border {
    box-sizing: border-box;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.table {
    display: table;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.h-10 {
    height: 2.5rem;
}
.h-12 {
    height: 3rem;
}
.h-14 {
    height: 3.5rem;
}
.h-16 {
    height: 4rem;
}
.h-20 {
    height: 5rem;
}
.h-24 {
    height: 6rem;
}
.h-32 {
    height: 8rem;
}
.h-4 {
    height: 1rem;
}
.h-40 {
    height: 10rem;
}
.h-44 {
    height: 11rem;
}
.h-48 {
    height: 12rem;
}
.h-5 {
    height: 1.25rem;
}
.h-56 {
    height: 14rem;
}
.h-64 {
    height: 16rem;
}
.h-8 {
    height: 2rem;
}
.h-\[420px\] {
    height: 420px;
}
.h-\[calc\(100vh-112px\)\] {
    height: calc(100vh - 112px);
}
.h-auto {
    height: auto;
}
.h-fit {
    height: -moz-fit-content;
    height: fit-content;
}
.h-full {
    height: 100%;
}
.h-screen {
    height: 100vh;
}
.max-h-7 {
    max-height: 1.75rem;
}
.max-h-8 {
    max-height: 2rem;
}
.max-h-96 {
    max-height: 24rem;
}
.max-h-\[13rem\] {
    max-height: 13rem;
}
.max-h-\[210px\] {
    max-height: 210px;
}
.min-h-\[350px\] {
    min-height: 350px;
}
.min-h-full {
    min-height: 100%;
}
.min-h-screen {
    min-height: 100vh;
}
.w-1\/2 {
    width: 50%;
}
.w-1\/3 {
    width: 33.333333%;
}
.w-10 {
    width: 2.5rem;
}
.w-11\/12 {
    width: 91.666667%;
}
.w-12 {
    width: 3rem;
}
.w-14 {
    width: 3.5rem;
}
.w-16 {
    width: 4rem;
}
.w-2\/3 {
    width: 66.666667%;
}
.w-20 {
    width: 5rem;
}
.w-3\/4 {
    width: 75%;
}
.w-32 {
    width: 8rem;
}
.w-36 {
    width: 9rem;
}
.w-40 {
    width: 10rem;
}
.w-5 {
    width: 1.25rem;
}
.w-6 {
    width: 1.5rem;
}
.w-64 {
    width: 16rem;
}
.w-8 {
    width: 2rem;
}
.w-80 {
    width: 20rem;
}
.w-96 {
    width: 24rem;
}
.w-\[135px\] {
    width: 135px;
}
.w-\[204px\] {
    width: 204px;
}
.w-\[218px\] {
    width: 218px;
}
.w-\[70px\] {
    width: 70px;
}
.w-auto {
    width: auto;
}
.w-full {
    width: 100%;
}
.w-screen {
    width: 100vw;
}
.min-w-0 {
    min-width: 0px;
}
.max-w-2xl {
    max-width: 42rem;
}
.max-w-7 {
    max-width: 1.75rem;
}
.max-w-8 {
    max-width: 2rem;
}
.max-w-80 {
    max-width: 20rem;
}
.max-w-\[560px\] {
    max-width: 560px;
}
.max-w-full {
    max-width: 100%;
}
.max-w-screen-md {
    max-width: 964px;
}
.max-w-sm {
    max-width: 24rem;
}
.max-w-xl {
    max-width: 36rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-20 {
    flex: 0 0 5rem;
}
.flex-auto {
    flex: 1 1 auto;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.shrink-0 {
    flex-shrink: 0;
}
.flex-grow {
    flex-grow: 1;
}
.flex-grow-0 {
    flex-grow: 0;
}
.grow {
    flex-grow: 1;
}
.grow-0 {
    flex-grow: 0;
}
.basis-1\/2 {
    flex-basis: 50%;
}
.basis-10 {
    flex-basis: 2.5rem;
}
.basis-14 {
    flex-basis: 3.5rem;
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
    cursor: pointer;
}
.list-none {
    list-style-type: none;
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-col-reverse {
    flex-direction: column-reverse;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.content-start {
    align-content: flex-start;
}
.content-end {
    align-content: flex-end;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.gap-x-10 {
    -moz-column-gap: 2.5rem;
         column-gap: 2.5rem;
}
.gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}
.gap-x-4 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}
.gap-x-6 {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}
.gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
}
.gap-y-4 {
    row-gap: 1rem;
}
.gap-y-6 {
    row-gap: 1.5rem;
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-stretch {
    align-self: stretch;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.break-words {
    overflow-wrap: break-word;
}
.break-all {
    word-break: break-all;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-none {
    border-radius: 0px;
}
.rounded-xl {
    border-radius: 0.75rem;
}
.rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
}
.rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-2 {
    border-width: 2px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-0 {
    border-bottom-width: 0px;
}
.border-l-0 {
    border-left-width: 0px;
}
.border-r-0 {
    border-right-width: 0px;
}
.border-s {
    border-inline-start-width: 1px;
}
.border-t {
    border-top-width: 1px;
}
.border-t-0 {
    border-top-width: 0px;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-none {
    border-style: none;
}
.border-\[\#27FF8B\] {
    --tw-border-opacity: 1;
    border-color: rgb(39 255 139 / var(--tw-border-opacity));
}
.border-grey-700 {
    --tw-border-opacity: 1;
    border-color: rgb(35 53 67 / var(--tw-border-opacity));
}
.border-grey-800 {
    --tw-border-opacity: 1;
    border-color: rgb(11 26 38 / var(--tw-border-opacity));
}
.border-light-background-default {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-light-highlight-main {
    --tw-border-opacity: 1;
    border-color: rgb(106 77 255 / var(--tw-border-opacity));
}
.border-light-neutral-accent {
    --tw-border-opacity: 1;
    border-color: rgb(222 227 231 / var(--tw-border-opacity));
}
.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-t-light-secondary-light {
    --tw-border-opacity: 1;
    border-top-color: rgb(239 241 243 / var(--tw-border-opacity));
}
.bg-\[\#27FF8B\] {
    --tw-bg-opacity: 1;
    background-color: rgb(39 255 139 / var(--tw-bg-opacity));
}
.bg-\[\#F5F8FA\] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 255 / var(--tw-bg-opacity));
}
.bg-dark-background-default {
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
}
.bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(230 250 243 / var(--tw-bg-opacity));
}
.bg-grey-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(222 227 231 / var(--tw-bg-opacity));
}
.bg-grey-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
.bg-light-background-default {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-light-highlight-light {
    --tw-bg-opacity: 1;
    background-color: rgb(244 242 255 / var(--tw-bg-opacity));
}
.bg-light-primary-light {
    --tw-bg-opacity: 1;
    background-color: rgb(224 242 255 / var(--tw-bg-opacity));
}
.bg-light-primary-lighter {
    --tw-bg-opacity: 1;
    background-color: rgb(240 248 255 / var(--tw-bg-opacity));
}
.bg-light-secondary-lighter {
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
}
.bg-light-text-secondary {
    --tw-bg-opacity: 1;
    background-color: rgb(90 115 134 / var(--tw-bg-opacity));
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 249 229 / var(--tw-bg-opacity));
}
.fill-light-primary-main {
    fill: #046BD9;
}
.fill-white {
    fill: #FFFFFF;
}
.stroke-light-primary-main {
    stroke: #046BD9;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.\!p-0 {
    padding: 0px !important;
}
.p-0 {
    padding: 0px;
}
.p-1 {
    padding: 0.25rem;
}
.p-16 {
    padding: 4rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-4 {
    padding: 1rem;
}
.p-6 {
    padding: 1.5rem;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.pb-10 {
    padding-bottom: 2.5rem;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-3 {
    padding-bottom: 0.75rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pl-12 {
    padding-left: 3rem;
}
.pl-2 {
    padding-left: 0.5rem;
}
.pl-2\.5 {
    padding-left: 0.625rem;
}
.pl-3 {
    padding-left: 0.75rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pl-6 {
    padding-left: 1.5rem;
}
.pl-8 {
    padding-left: 2rem;
}
.pl-\[3\.375rem\] {
    padding-left: 3.375rem;
}
.pl-\[50px\] {
    padding-left: 50px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-2 {
    padding-right: 0.5rem;
}
.pr-4 {
    padding-right: 1rem;
}
.pr-6 {
    padding-right: 1.5rem;
}
.pt-0 {
    padding-top: 0px;
}
.pt-10 {
    padding-top: 2.5rem;
}
.pt-12 {
    padding-top: 3rem;
}
.pt-14 {
    padding-top: 3.5rem;
}
.pt-2 {
    padding-top: 0.5rem;
}
.pt-20 {
    padding-top: 5rem;
}
.pt-3 {
    padding-top: 0.75rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pt-8 {
    padding-top: 2rem;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.align-middle {
    vertical-align: middle;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-5xl {
    font-size: 3rem;
    line-height: 1;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.lowercase {
    text-transform: lowercase;
}
.capitalize {
    text-transform: capitalize;
}
.italic {
    font-style: italic;
}
.leading-4 {
    line-height: 1rem;
}
.leading-6 {
    line-height: 1.5rem;
}
.leading-\[56px\] {
    line-height: 56px;
}
.leading-tight {
    line-height: 1.25;
}
.tracking-normal {
    letter-spacing: 0em;
}
.tracking-wider {
    letter-spacing: 0.05em;
}
.text-\[\#2f44ff\] {
    --tw-text-opacity: 1;
    color: rgb(47 68 255 / var(--tw-text-opacity));
}
.text-dark-secondary-light {
    --tw-text-opacity: 1;
    color: rgb(90 115 134 / var(--tw-text-opacity));
}
.text-grey-300 {
    --tw-text-opacity: 1;
    color: rgb(189 199 207 / var(--tw-text-opacity));
}
.text-light-error-main {
    --tw-text-opacity: 1;
    color: rgb(217 22 54 / var(--tw-text-opacity));
}
.text-light-highlight-main {
    --tw-text-opacity: 1;
    color: rgb(106 77 255 / var(--tw-text-opacity));
}
.text-light-neutral-accent {
    --tw-text-opacity: 1;
    color: rgb(222 227 231 / var(--tw-text-opacity));
}
.text-light-primary-main {
    --tw-text-opacity: 1;
    color: rgb(4 107 217 / var(--tw-text-opacity));
}
.text-light-secondary-main {
    --tw-text-opacity: 1;
    color: rgb(35 53 67 / var(--tw-text-opacity));
}
.text-light-success-dark {
    --tw-text-opacity: 1;
    color: rgb(9 111 78 / var(--tw-text-opacity));
}
.text-light-text-primary {
    --tw-text-opacity: 1;
    color: rgb(35 53 67 / var(--tw-text-opacity));
}
.text-light-text-secondary {
    --tw-text-opacity: 1;
    color: rgb(90 115 134 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.line-through {
    text-decoration-line: line-through;
}
.opacity-50 {
    opacity: 0.5;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0px_1px_0px_rgba\(17\2c 17\2c 17\2c 0\.15\)\2c 0px_1px_5px_0px_rgba\(17\2c 17\2c 17\2c 0\.15\)\] {
    --tw-shadow: 0px 0px 1px 0px rgba(17,17,17,0.15),0px 1px 5px 0px rgba(17,17,17,0.15);
    --tw-shadow-colored: 0px 0px 1px 0px var(--tw-shadow-color), 0px 1px 5px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
    outline-style: solid;
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

#fc_frame {
  z-index: 1000 !important;
}

.stripe-border {
  border: 1px solid;
}
.stripe-border-l {
  border-left: 1px solid;
}
.stripe-border-b {
  border-bottom: 1px solid;
}

.slide-alert .sc-content {
  width: 100%;
}

.global-search .sc-drawer-surface {
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    width: 100%;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 600px) {
    .global-search .sc-drawer-surface {
        margin-top: 7rem;
        margin-bottom: 7rem;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        max-height: calc(100vh - 224px);
        min-height: 0px;
        max-width: 42rem;
        background-color: transparent;
    }
}
.global-search .sc-drawer-surface .sc-drawer-container {
    padding: 0px;
}
@media (min-width: 600px) {
    .global-search .sc-drawer-surface .sc-drawer-container {
        background-color: transparent;
    }
    .global-search .global-search-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
.global-search .global-search-field {
    border-bottom-width: 1px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(222 227 231 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(245 248 250 / var(--tw-bg-opacity));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.global-search .global-search-field:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(35 53 67 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}
@media (min-width: 600px) {
    .global-search .global-search-field {
        border-style: none;
        background-color: transparent;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
.global-search .global-search-input-container {
    height: 3rem;
}
@media (min-width: 600px) {
    .global-search .global-search-input-container {
        --tw-shadow: 0px 8px 24px rgba(17, 17, 17, 0.1);
        --tw-shadow-colored: 0px 8px 24px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .global-search .global-search-input-container:is(.dark *) {
        --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.6);
        --tw-shadow-colored: 0px 4px 24px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
}
.global-search .global-search-input-container .global-search-input {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.global-search .global-search-results-container {
    max-height: calc(100vh - 130px);
    overflow-y: auto;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.global-search .global-search-results-container:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
}
@media (min-width: 600px) {
    .global-search .global-search-results-container {
        margin-top: 0.5rem;
        max-height: calc(100vh - 280px);
        border-radius: 0.5rem;
        --tw-shadow: 0px 8px 24px rgba(17, 17, 17, 0.1);
        --tw-shadow-colored: 0px 8px 24px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .global-search .global-search-results-container:is(.dark *) {
        --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.6);
        --tw-shadow-colored: 0px 4px 24px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
}
.global-search .global-search-result {
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    border-style: none;
    background-color: transparent;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.global-search .global-search-result:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(240 248 255 / var(--tw-bg-opacity));
}
.global-search .global-search-result:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}

.paywall-scaler {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
}

/* The image URLs below reference the "production" bucket in S3 and will be used for all envs */
.paywall-scaler.cart-abandonment {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/cart-abandonment-bg-xs.png');
}
@media (min-width: 600px) {
    .paywall-scaler.cart-abandonment {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/cart-abandonment-bg-sm.png');
    }
}
@media (min-width: 964px) {
    .paywall-scaler.cart-abandonment {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/cart-abandonment-bg-md.png');
    }
}
@media (min-width: 1456px) {
    .paywall-scaler.cart-abandonment {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/cart-abandonment-bg-lg.png');
    }
}
@media (min-width: 1792px) {
    .paywall-scaler.cart-abandonment {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/cart-abandonment-bg-xl.png');
    }
}

.paywall-scaler.funnels {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/funnels-bg-xs.png');
}

@media (min-width: 600px) {
    .paywall-scaler.funnels {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/funnels-bg-sm.png');
    }
}

@media (min-width: 964px) {
    .paywall-scaler.funnels {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/funnels-bg-md.png');
    }
}

@media (min-width: 1456px) {
    .paywall-scaler.funnels {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/funnels-bg-lg.png');
    }
}

@media (min-width: 1792px) {
    .paywall-scaler.funnels {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/funnels-bg-xl.png');
    }
}

.paywall-scaler.pages {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/pages-bg-xs.png');
}

@media (min-width: 600px) {
    .paywall-scaler.pages {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/pages-bg-sm.png');
    }
}

@media (min-width: 964px) {
    .paywall-scaler.pages {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/pages-bg-md.png');
    }
}

@media (min-width: 1456px) {
    .paywall-scaler.pages {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/pages-bg-lg.png');
    }
}

@media (min-width: 1792px) {
    .paywall-scaler.pages {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/pages-bg-xl.png');
    }
}

.paywall-scaler.subscription-analytics {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-analytics-bg-xs.png');
}

@media (min-width: 600px) {
    .paywall-scaler.subscription-analytics {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-analytics-bg-sm.png');
    }
}

@media (min-width: 964px) {
    .paywall-scaler.subscription-analytics {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-analytics-bg-md.png');
    }
}

@media (min-width: 1456px) {
    .paywall-scaler.subscription-analytics {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-analytics-bg-lg.png');
    }
}

@media (min-width: 1792px) {
    .paywall-scaler.subscription-analytics {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-analytics-bg-xl.png');
    }
}

.paywall-scaler.subscription-saver {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-saver-bg-xs.png');
}

@media (min-width: 600px) {
    .paywall-scaler.subscription-saver {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-saver-bg-sm.png');
    }
}

@media (min-width: 964px) {
    .paywall-scaler.subscription-saver {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-saver-bg-md.png');
    }
}

@media (min-width: 1456px) {
    .paywall-scaler.subscription-saver {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-saver-bg-lg.png');
    }
}

@media (min-width: 1792px) {
    .paywall-scaler.subscription-saver {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/subscription-saver-bg-xl.png');
    }
}

.paywall-scaler.upsells {
    background-image: url('https://static.samcart.com/dashboard/scaler-paywall/upsells-bg-xs.png');
}

@media (min-width: 600px) {
    .paywall-scaler.upsells {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/upsells-bg-sm.png');
    }
}

@media (min-width: 964px) {
    .paywall-scaler.upsells {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/upsells-bg-md.png');
    }
}

@media (min-width: 1456px) {
    .paywall-scaler.upsells {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/upsells-bg-lg.png');
    }
}

@media (min-width: 1792px) {
    .paywall-scaler.upsells {
        background-image: url('https://static.samcart.com/dashboard/scaler-paywall/upsells-bg-xl.png');
    }
}

.paywall-scaler-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
}

.layout-main-full-width:has(.paywall-scaler),
.layout-main-max-width:has(.paywall-scaler) {
    padding: 0px;
}

@media (min-width: 1456px) {
  .lg\:max-w-content-lg {
    max-width: 1200px;
  }
}

@media (min-width: 1792px) {
  .xl\:max-w-content-xl {
    max-width: 1536px;
  }
}

.hover\:grey-text:hover {
    --tw-text-opacity: 1;
    color: rgb(90 115 134 / var(--tw-text-opacity));
}

.hover\:grey-text:hover:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(189 199 207 / var(--tw-text-opacity));
}

@media (min-width: 964px) {
  .settings-layout .md\:settings-layout-height-desktop.settings-layout-main {
    height: calc(100vh - 5rem);
  }
}

.hover\:bg-light-secondary-light:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(239 241 243 / var(--tw-bg-opacity));
}

.hover\:underline:hover {
    text-decoration-line: underline;
}

.dark\:border-dark-background-default:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(11 26 38 / var(--tw-border-opacity));
}

.dark\:border-dark-highlight-main:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(189 176 255 / var(--tw-border-opacity));
}

.dark\:border-dark-neutral-accent:is(.dark *) {
    --tw-border-opacity: 1;
    border-color: rgb(35 53 67 / var(--tw-border-opacity));
}

.dark\:bg-dark-background-default:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(11 26 38 / var(--tw-bg-opacity));
}

.dark\:bg-dark-highlight-light:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(31 42 74 / var(--tw-bg-opacity));
}

.dark\:bg-dark-primary-light:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(2 66 145 / var(--tw-bg-opacity));
}

.dark\:bg-dark-primary-lighter:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(26 52 73 / var(--tw-bg-opacity));
}

.dark\:bg-dark-secondary-lighter:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(35 53 67 / var(--tw-bg-opacity));
}

.dark\:bg-dark-text-secondary:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(189 199 207 / var(--tw-bg-opacity));
}

.dark\:fill-dark-primary-main:is(.dark *) {
    fill: #8CCDFF;
}

.dark\:stroke-dark-primary-main:is(.dark *) {
    stroke: #8CCDFF;
}

.dark\:text-dark-error-main:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 153 161 / var(--tw-text-opacity));
}

.dark\:text-dark-highlight-main:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(189 176 255 / var(--tw-text-opacity));
}

.dark\:text-dark-primary-main:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(140 205 255 / var(--tw-text-opacity));
}

.dark\:text-dark-secondary-main:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(239 241 243 / var(--tw-text-opacity));
}

.dark\:text-dark-success-dark:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(10 204 139 / var(--tw-text-opacity));
}

.dark\:text-dark-text-primary:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:hover\:bg-dark-secondary-light:hover:is(.dark *) {
    --tw-bg-opacity: 1;
    background-color: rgb(90 115 134 / var(--tw-bg-opacity));
}

@media (min-width: 0px) {
    .xs\:w-full {
        width: 100%;
    }
}

@media (min-width: 600px) {
    .sm\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .sm\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .sm\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .sm\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .sm\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .sm\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .sm\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .sm\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .sm\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .sm\:col-start-1 {
        grid-column-start: 1;
    }
    .sm\:col-start-7 {
        grid-column-start: 7;
    }
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .sm\:mb-0 {
        margin-bottom: 0px;
    }
    .sm\:mb-1 {
        margin-bottom: 0.25rem;
    }
    .sm\:mb-2 {
        margin-bottom: 0.5rem;
    }
    .sm\:mb-20 {
        margin-bottom: 5rem;
    }
    .sm\:mb-4 {
        margin-bottom: 1rem;
    }
    .sm\:ml-6 {
        margin-left: 1.5rem;
    }
    .sm\:mr-4 {
        margin-right: 1rem;
    }
    .sm\:mr-6 {
        margin-right: 1.5rem;
    }
    .sm\:mt-0 {
        margin-top: 0px;
    }
    .sm\:mt-3 {
        margin-top: 0.75rem;
    }
    .sm\:block {
        display: block;
    }
    .sm\:inline-block {
        display: inline-block;
    }
    .sm\:inline {
        display: inline;
    }
    .sm\:flex {
        display: flex;
    }
    .sm\:grid {
        display: grid;
    }
    .sm\:hidden {
        display: none;
    }
    .sm\:h-20 {
        height: 5rem;
    }
    .sm\:h-40 {
        height: 10rem;
    }
    .sm\:h-48 {
        height: 12rem;
    }
    .sm\:h-72 {
        height: 18rem;
    }
    .sm\:h-8 {
        height: 2rem;
    }
    .sm\:w-1\/2 {
        width: 50%;
    }
    .sm\:w-20 {
        width: 5rem;
    }
    .sm\:w-80 {
        width: 20rem;
    }
    .sm\:w-96 {
        width: 24rem;
    }
    .sm\:w-\[204px\] {
        width: 204px;
    }
    .sm\:w-auto {
        width: auto;
    }
    .sm\:w-fit {
        width: -moz-fit-content;
        width: fit-content;
    }
    .sm\:w-full {
        width: 100%;
    }
    .sm\:min-w-\[380px\] {
        min-width: 380px;
    }
    .sm\:max-w-sm {
        max-width: 24rem;
    }
    .sm\:flex-1 {
        flex: 1 1 0%;
    }
    .sm\:grow {
        flex-grow: 1;
    }
    .sm\:basis-20 {
        flex-basis: 5rem;
    }
    .sm\:basis-24 {
        flex-basis: 6rem;
    }
    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:items-start {
        align-items: flex-start;
    }
    .sm\:items-end {
        align-items: flex-end;
    }
    .sm\:items-center {
        align-items: center;
    }
    .sm\:justify-center {
        justify-content: center;
    }
    .sm\:justify-between {
        justify-content: space-between;
    }
    .sm\:gap-6 {
        gap: 1.5rem;
    }
    .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:p-6 {
        padding: 1.5rem;
    }
    .sm\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sm\:pr-4 {
        padding-right: 1rem;
    }
    .sm\:pt-10 {
        padding-top: 2.5rem;
    }
    .sm\:text-right {
        text-align: right;
    }
}

@media (min-width: 964px) {
    .md\:fixed {
        position: fixed;
    }
    .md\:absolute {
        position: absolute;
    }
    .md\:sticky {
        position: sticky;
    }
    .md\:inset-0 {
        inset: 0px;
    }
    .md\:top-0 {
        top: 0px;
    }
    .md\:z-50 {
        z-index: 50;
    }
    .md\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .md\:col-span-12 {
        grid-column: span 12 / span 12;
    }
    .md\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .md\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .md\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .md\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .md\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .md\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .md\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .md\:col-start-8 {
        grid-column-start: 8;
    }
    .md\:row-span-4 {
        grid-row: span 4 / span 4;
    }
    .md\:row-span-5 {
        grid-row: span 5 / span 5;
    }
    .md\:row-start-1 {
        grid-row-start: 1;
    }
    .md\:row-start-6 {
        grid-row-start: 6;
    }
    .md\:-my-10 {
        margin-top: -2.5rem;
        margin-bottom: -2.5rem;
    }
    .md\:-mb-0 {
        margin-bottom: -0px;
    }
    .md\:-mt-10 {
        margin-top: -2.5rem;
    }
    .md\:mb-0 {
        margin-bottom: 0px;
    }
    .md\:mb-6 {
        margin-bottom: 1.5rem;
    }
    .md\:mb-8 {
        margin-bottom: 2rem;
    }
    .md\:ml-4 {
        margin-left: 1rem;
    }
    .md\:ml-6 {
        margin-left: 1.5rem;
    }
    .md\:ml-auto {
        margin-left: auto;
    }
    .md\:mr-6 {
        margin-right: 1.5rem;
    }
    .md\:mt-0 {
        margin-top: 0px;
    }
    .md\:mt-16 {
        margin-top: 4rem;
    }
    .md\:mt-6 {
        margin-top: 1.5rem;
    }
    .md\:mt-7 {
        margin-top: 1.75rem;
    }
    .md\:mt-8 {
        margin-top: 2rem;
    }
    .md\:block {
        display: block;
    }
    .md\:flex {
        display: flex;
    }
    .md\:grid {
        display: grid;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-10 {
        height: 2.5rem;
    }
    .md\:h-16 {
        height: 4rem;
    }
    .md\:h-20 {
        height: 5rem;
    }
    .md\:h-28 {
        height: 7rem;
    }
    .md\:h-36 {
        height: 9rem;
    }
    .md\:h-8 {
        height: 2rem;
    }
    .md\:h-\[calc\(100vh-64px\)\] {
        height: calc(100vh - 64px);
    }
    .md\:h-auto {
        height: auto;
    }
    .md\:h-full {
        height: 100%;
    }
    .md\:min-h-\[350px\] {
        min-height: 350px;
    }
    .md\:w-1\/2 {
        width: 50%;
    }
    .md\:w-10 {
        width: 2.5rem;
    }
    .md\:w-20 {
        width: 5rem;
    }
    .md\:w-56 {
        width: 14rem;
    }
    .md\:w-64 {
        width: 16rem;
    }
    .md\:w-96 {
        width: 24rem;
    }
    .md\:w-\[277px\] {
        width: 277px;
    }
    .md\:w-\[480px\] {
        width: 480px;
    }
    .md\:w-\[calc\(100\%-256px\)\] {
        width: calc(100% - 256px);
    }
    .md\:w-auto {
        width: auto;
    }
    .md\:min-w-\[400px\] {
        min-width: 400px;
    }
    .md\:max-w-\[360px\] {
        max-width: 360px;
    }
    .md\:flex-1 {
        flex: 1 1 0%;
    }
    .md\:flex-auto {
        flex: 1 1 auto;
    }
    .md\:flex-grow-0 {
        flex-grow: 0;
    }
    .md\:grow {
        flex-grow: 1;
    }
    .md\:basis-1 {
        flex-basis: 0.25rem;
    }
    .md\:basis-1\/3 {
        flex-basis: 33.333333%;
    }
    .md\:basis-2\/3 {
        flex-basis: 66.666667%;
    }
    .md\:basis-20 {
        flex-basis: 5rem;
    }
    .md\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .md\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:grid-rows-1 {
        grid-template-rows: repeat(1, minmax(0, 1fr));
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:flex-col {
        flex-direction: column;
    }
    .md\:items-start {
        align-items: flex-start;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:justify-center {
        justify-content: center;
    }
    .md\:justify-between {
        justify-content: space-between;
    }
    .md\:gap-4 {
        gap: 1rem;
    }
    .md\:gap-6 {
        gap: 1.5rem;
    }
    .md\:gap-x-10 {
        -moz-column-gap: 2.5rem;
             column-gap: 2.5rem;
    }
    .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem * var(--tw-space-x-reverse));
        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
    .md\:justify-self-end {
        justify-self: end;
    }
    .md\:p-10 {
        padding: 2.5rem;
    }
    .md\:pb-6 {
        padding-bottom: 1.5rem;
    }
    .md\:pl-0 {
        padding-left: 0px;
    }
    .md\:pr-6 {
        padding-right: 1.5rem;
    }
    .md\:pt-0 {
        padding-top: 0px;
    }
    .md\:pt-10 {
        padding-top: 2.5rem;
    }
    .md\:text-left {
        text-align: left;
    }
    .md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .md\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .md\:font-semibold {
        font-weight: 600;
    }
}

@media (min-width: 1456px) {
    .lg\:sticky {
        position: sticky;
    }
    .lg\:top-36 {
        top: 9rem;
    }
    .lg\:col-span-1 {
        grid-column: span 1 / span 1;
    }
    .lg\:col-span-2 {
        grid-column: span 2 / span 2;
    }
    .lg\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .lg\:col-span-4 {
        grid-column: span 4 / span 4;
    }
    .lg\:col-span-5 {
        grid-column: span 5 / span 5;
    }
    .lg\:col-span-6 {
        grid-column: span 6 / span 6;
    }
    .lg\:col-span-7 {
        grid-column: span 7 / span 7;
    }
    .lg\:col-span-8 {
        grid-column: span 8 / span 8;
    }
    .lg\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .lg\:mb-0 {
        margin-bottom: 0px;
    }
    .lg\:mb-16 {
        margin-bottom: 4rem;
    }
    .lg\:mr-6 {
        margin-right: 1.5rem;
    }
    .lg\:block {
        display: block;
    }
    .lg\:inline {
        display: inline;
    }
    .lg\:hidden {
        display: none;
    }
    .lg\:h-40 {
        height: 10rem;
    }
    .lg\:w-auto {
        width: auto;
    }
    .lg\:w-full {
        width: 100%;
    }
    .lg\:min-w-\[480px\] {
        min-width: 480px;
    }
    .lg\:max-w-\[490px\] {
        max-width: 490px;
    }
    .lg\:max-w-full {
        max-width: 100%;
    }
    .lg\:max-w-md {
        max-width: 28rem;
    }
    .lg\:grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .lg\:grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .lg\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .lg\:flex-row {
        flex-direction: row;
    }
    .lg\:flex-col {
        flex-direction: column;
    }
    .lg\:flex-nowrap {
        flex-wrap: nowrap;
    }
    .lg\:items-center {
        align-items: center;
    }
    .lg\:gap-16 {
        gap: 4rem;
    }
    .lg\:gap-6 {
        gap: 1.5rem;
    }
    .lg\:text-nowrap {
        text-wrap: nowrap;
    }
    .lg\:bg-light-primary-lighter {
        --tw-bg-opacity: 1;
        background-color: rgb(240 248 255 / var(--tw-bg-opacity));
    }
    .lg\:bg-transparent {
        background-color: transparent;
    }
    .lg\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }
    .lg\:pt-16 {
        padding-top: 4rem;
    }
    .lg\:dark\:bg-dark-primary-lighter:is(.dark *) {
        --tw-bg-opacity: 1;
        background-color: rgb(26 52 73 / var(--tw-bg-opacity));
    }
    .lg\:dark\:bg-transparent:is(.dark *) {
        background-color: transparent;
    }
}

@media (min-width: 1792px) {
    .xl\:col-span-3 {
        grid-column: span 3 / span 3;
    }
    .xl\:col-span-9 {
        grid-column: span 9 / span 9;
    }
    .xl\:mb-8 {
        margin-bottom: 2rem;
    }
    .xl\:h-12 {
        height: 3rem;
    }
    .xl\:w-12 {
        width: 3rem;
    }
    .xl\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .xl\:flex-row {
        flex-direction: row;
    }
}

@media print {
    .print\:hidden {
        display: none;
    }
    .print\:w-auto {
        width: auto;
    }
}

.first\:\[\&\>\*\.MuiAccordion-root\]\:border-t-0>*.MuiAccordion-root:first-child {
    border-top-width: 0px;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9b720';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d9b720';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d9b720 {font-family: '__Inter_d9b720', '__Inter_Fallback_d9b720';font-style: normal
}

